.badged-icon {
    position: relative;

    >.fa {
        font-size: inherit;
    }

    >.icon-badge {
        font-size: .4em;
        position: absolute;
        top: .5em;
        left: -.1em;
        border-radius: 50%;
        min-width: 1.1em;
        line-height: 1.1em;
        text-align: center;
    }

    >.large-red-badge {
        font-size: .6em;
        position: absolute;
        top: .5em;
        left: .2em;
        border-radius: 50%;
        min-width: 1.1em;
        line-height: 1.1em;
        text-align: center;
        color: red;
    }
}

$icons: (
    "draft",
    "new-req",
    "json-array",
    "json-boolean",
    "json-null",
    "json-number",
    "json-other",
    "json-string",
    "json-object"
);
$icon-height: 1em;
$icon-width: $icon-height;


.icon:not(option) {

    &::before {
        display: inline-block;
        background-repeat: no-repeat;
        background-size: $icon-width * .9 $icon-height * .9;
        background-position-x: center;
        background-position-y: center;
        width: $icon-width;
        height: $icon-height;
        line-height: 1em;
        vertical-align: middle;
        content: " ";
        margin-right: 4px;
    }

    @each $icon in $icons {
        &.icon-#{$icon}::before {
            background-image: url("../images/" + $icon + ".svg");
        }
    }



    &.icon-new-req::before {
        transform: translateY($icon-height * .2);
    }
}

option.icon {
    background-repeat: no-repeat;
    background-size: $icon-width * .9 $icon-height * .9;
    background-position-x: center;
    background-position-y: center;

    @each $icon in $icons {
        &.icon-#{$icon} {
            background-image: url("../images/" + $icon + ".svg");
        }
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.2);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.pulse-effect {
    animation: pulse 1s infinite;
}