.modal {
    div.uweEntityModal {
        div.title {
            background-color: #214675;
            color: white;
            border-radius: 3px 3px 0 0;
            box-sizing: content-box;

            span.title {
                font-weight: bold;
                font-size: 25px;
                padding: 10px;
            }

        }
    }
}