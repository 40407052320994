nav.navbar {
    background-color: $navbar-bg-color;
    color: $navbar-color;
    height: $navbar-height;

    .nav-icon {
        font-size: 2rem;
        color: $navbar-color;

        .icon-badge {
            color: $navbar-badge-color;
        }
    }


    .clock.nav-clock {
        font-size: 3rem;
        margin-right: 1rem;
    }
    
    .nav-logo-name {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .nav.navbar-nav {
        >.navbar-text {
            display: flex;
            align-items: center;
            padding-left: 5px;
            padding-right: 5px;
        }

        >.nav-item {
            display: flex;
            align-items: center;
        }
    }

    a.nav-link {
        color: $gray;
    }

    .navbar-help-text {
        color: $navbar-color
    }

    .navbar-text.text-title {
        color: $pd-color-5;
        font-weight: bold;
    }
}

.medium-panel {
    width: 350px;
    max-height: 550px;
    overflow-y: auto;
}

.tooltip>div.tooltip-inner {
    background-color: $navbar-bg-color !important;
    color: $navbar-color !important;
    margin-left: -9px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-weight: bold;
}

.bs-tooltip-auto[x-placement^="right"] span.arrow::before,
.bs-tooltip-right .arrow::before {
    border-right-color: $navbar-bg-color;
    opacity: 0%;
}

.nav-notification-mobile {
    display: none;
}

@media only screen and (min-width: 850px) {
    
    .nav-logo-name-mobile {
        display: none;
    }
}